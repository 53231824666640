export default {
  mounted() {
    this.$nextTick(_ => {
      if (!this.isEmbedded) {
        this.focus();
      }
    });
  },
  methods: {
    focus() {
      this.$refs.table && this.$refs.table.focus();
    }
  }
};
